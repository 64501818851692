<template>
  <div class="form">
    <h3>Olá</h3>
    <p>Para remover seu cadastro é necessário seus dados:</p>
    
    <div>
      <validation-observer ref="formValidation">
        <b-form @submit.prevent="onSubmit">
          <b-form-group id="input-group-1" label="Nome" label-for="name">
            <validation-provider
              #default="{ errors }"
              name="Nome"
              vid="name"
              rules="required|min:4"
            >
              <b-form-input
                  id="name"
                  name="name"
                  v-model="form.name"
                  placeholder="Informe seu nome"
                  :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-row>
            <b-col
                cols="12"
                md="6"
            >
              <b-form-group class="" id="input-group-2" label="CPF" label-for="cpf">
                <validation-provider
                    #default="{ errors }"
                    name="CPF"
                    vid="cpf"
                    rules="required|cpf"
                >
                  <b-form-input
                      id="cpf"
                      name="cpf"
                      v-model="form.cpf"
                      v-mask="'###.###.###-##'"
                      placeholder="Informe seu CPF"
                      :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
                cols="12"
                md="6"
            >
              <b-form-group class="" id="input-group-2" label="E-mail" label-for="email">
                <validation-provider
                    #default="{ errors }"
                    name="E-mail"
                    vid="email"
                    rules="required|email"
                >
                  <b-form-input
                      id="email"
                      name="email"
                      v-model="form.email"
                      placeholder="Informe seu E-mail"
                      :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="btn-submit-container">
            <b-button type="submit" variant="primary">Solicitar remoção</b-button>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend, localize} from 'vee-validate'
import ptBR from 'vee-validate/dist/locale/pt_BR.json'
import {
  required,
  email,
  min,
} from 'vee-validate/dist/rules'
import axios from 'axios'
import Swal from 'sweetalert2'
import cpfValidator from '../validations/cpf';
import { validatorPassword } from '../validations/password';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true
})

export default {
  components: {  ValidationProvider, ValidationObserver },
  props: {
    token: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      required: extend('required', required),
      email: extend('email', email),
      min: extend('min', min),
      cpf: extend('cpf', {
        validate: cpfValidator,
        message: 'CPF inválido',
      }),
      password: extend('password', {
        validate: validatorPassword,
        message: '{_field_} deve conter pelo menos uma letra maiúscula, uma minúscula, um caractere especial e um dígito e ter no mínimo 8 caracteres',
      }),
      accepted: false,
      invalidPassword: false,
      showPassword: false,
      specialChars: '!?@#$%&*()',
      passwordErrors: {
        length: null,
        lowercase: null,
        uppercase: null,
        special: null,
        numbers: null,
      },
      form: {
        cpf: '',
        name: '',
        email: '',
      },
    }
  },
  created() {
    localize('pt_BR', {
      messages: ptBR.messages,
      names: {
        email: 'Email',
        name: 'Nome',
        password: 'Password',
      },
      fields: {
        password: {
          min: 'O {_field_} é muito pequeno. Escolha uma senha mais forte.',
        },
      },
    })
  },
  methods: {
    async onSubmit() {
      const success = await this.$refs.formValidation.validate()
      if (!success) return
      
      try {
        const formData = {
          ...this.form,
          cpf: this.form.cpf.replace(/\D/g, ''),
          recaptcha: await this.$recaptcha('form') 
        };
        axiosInstance
          .post(`/lgpd/remove`, formData)
          .then(() => {
            this.$router.push({ name: 'FormSubmitted' })
          })
          .catch(error => {
            const errors = (error.response && error.response.status === 422 && error.response.data.errors) || {}
            if (Object.keys(errors).length) {
              const messages = Object.keys(errors).map((key) => {
                return errors[key].join('<br/>')
              }).join('<br/>')
              Swal.fire({
                title: 'Inválido',
                html: messages,
                icon: 'error',
                confirmButtonText: 'Continuar'
              })
            } else if (error.response.data && error.response.data.message) {
              Swal.fire({
                title: 'Inválido',
                text: error.response.data.message,
                icon: 'error',
                confirmButtonText: 'Continuar'
              })
            }
          });
      } catch (err) {
        console.log(err)
      }
    },
  }
}
</script>

<style scoped lang="sass">
$color: #ea5d23
$colorDanger: #dc3545
$colorSuccess: #28a745

ul 
  padding-left: 16px 
  margin-bottom: 0
  li
    font-size: 12px
  li.has-error
    color: $colorDanger
  li.is-valid
    color: $colorSuccess

.password-btn
  border: 1px solid #ced4da !important
  outline: none
  background-color: transparent !important
  color: $color

.term-link
  text-decoration: underline
  &:hover
    color: #ea5d23!important

.form
  background-color: #ffffff
  padding: 18px
  color: #626262
  text-align: left   
  h3
    margin: 10px 0 20px 0
  .btn-submit-container
    text-align: right
    margin-top: 10px
    button
      font-weight: 600
      font-size: 14px
      line-height: 100%
      padding: 16px 24px
      border-radius: 0.358rem
      border: 1px solid $color !important
      background-color: $color !important
      color: #ffffff
  .Password
    width: 100%!important
    max-width: 100%!important
</style>

<style lang="sass">
  .swal2-html-container
    text-align: left
  .form
    label
      margin-bottom: 0
      
  .Password__strength-meter
    margin: 5px auto 5px!important
</style>
