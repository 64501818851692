<template>
  <div id="app" class="container">
    <img class="logo" alt="IMEDIATO logo" src="./assets/logo.svg" v-if="show">
    <router-view/>
    <div class="download-badges" v-if="show">
      <h4>Baixe o app</h4>
      <div class="badges">
        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.globalmed"><img class="download-badge" alt="Download on google play" src="./assets/playstore.png"></a>
        <a target="_blank" href="https://apps.apple.com/br/app/imediato-empresas/id1620990595"><img class="download-badge play-store" alt="Download on play store" src="./assets/itunes.png"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      show: true,
    }
  },
  created() {
    if (this.$route.name === 'CurrentTerm') {
      this.show = false;      
    }
  }
}
</script>

<style lang="sass" scoped>
.container
  margin: 0 auto
  max-width: 600px
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50
  
.container
  .logo
    width: 350px
    margin: 30px 0 30px 0
.download-badges
  padding-top: 20px
  padding-bottom: 40px
  margin: 0 auto
  display: flex
  flex-direction: column
  h4
    text-align: center
    margin: 10px 0
    width: 100%
    font-size: 14px
    font-weight: normal
  .badges
    display: flex
    flex-direction: column
    align-self: center
    .download-badge
      max-width: 200px
      width: 100%
      margin: 5px
</style>
